<script setup lang="ts">
import { AnalyticsEvent } from "~/types/analytics";
import type { ButtonVariant } from "#ui/types";

let interval: NodeJS.Timeout | null = null;
const time = ref(0);
const isRunning = ref(false);
const isPaused = ref(false);
const showStopConfirmation = ref(false);

const { $posthog } = useNuxtApp();
const route = useRoute();

function saveState() {
  const state = {
    time: time.value,
    isRunning: isRunning.value,
    isPaused: isPaused.value,
    lastUpdate: Date.now(),
  };
  localStorage.setItem("stopwatch-state", JSON.stringify(state));
}

onMounted(() => {
  const savedState = JSON.parse(
    localStorage.getItem("stopwatch-state") || "{}",
  ) as { time: number; isPaused: boolean; isRunning: boolean; lastUpdate: number; };

  if (savedState.time) {
    time.value = savedState.time;
    isPaused.value = savedState.isPaused || false;

    if (savedState.isRunning) {
      const lastUpdate = savedState.lastUpdate;
      if (lastUpdate) {
        const timePassed = Math.min(
          Math.floor((Date.now() - lastUpdate) / 1000),
          24 * 60 * 60,
        );
        time.value += timePassed;
      }
      startTimer();
    }
  }

  window.addEventListener("beforeunload", saveState);
});

// Cleanup
onUnmounted(() => {
  if (interval) {
    clearInterval(interval);
  }
  window.removeEventListener("beforeunload", saveState);
});

// Every time the time, isRunning, or isPaused changes, save the state
watch([time, isRunning, isPaused], () => {
  saveState();
}, { deep: true });

function startTimer(e?: Event) {
  if (e)
    e.preventDefault();

  isRunning.value = true;
  isPaused.value = false;

  if (interval) {
    clearInterval(interval);
  }

  interval = setInterval(() => {
    time.value++;
  }, 1000);

  $posthog.capture(AnalyticsEvent.StopwatchStarted);
};

function pauseTimer(e?: Event) {
  if (e)
    e.preventDefault();

  if (interval) {
    clearInterval(interval);
    interval = null;
  }
  isRunning.value = false;
  isPaused.value = true;
  saveState();

  $posthog.capture(AnalyticsEvent.StopwatchPaused);
};

function stopTimer(e?: Event) {
  if (e)
    e.preventDefault();

  if (interval) {
    clearInterval(interval);
    interval = null;
  }
  isRunning.value = false;
  isPaused.value = false;
  time.value = 0;
  localStorage.removeItem("stopwatch-state");

  $posthog.capture(AnalyticsEvent.StopwatchStopped);
};

const formattedTime = computed(() => {
  const hours = Math.floor(time.value / 3600);
  const minutes = Math.floor((time.value % 3600) / 60);
  const seconds = time.value % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
});

async function handleTimesheetClick() {
  const hours = Math.floor(time.value / 3600);
  const minutes = Math.floor((time.value % 3600) / 60);

  if (hours < 0 || minutes < 0 || minutes >= 60) {
    console.error("Invalid time values:", { hours, minutes });
    return;
  }

  const timesheetData = {
    hours,
    minutes,
    timestamp: Date.now(),
    source: "stopwatch",
  };

  if (route.path !== "/timesheets") {
    try {
      localStorage.setItem(
        "stopwatch-timesheet-data",
        JSON.stringify(timesheetData),
      );
    }
    catch (error) {
      console.error("Failed to save timesheet data:", error);
      return;
    }
    await navigateTo("/timesheets");
  }
  else {
    localStorage.setItem("stopwatch-timesheet-data", JSON.stringify(timesheetData));
    window.dispatchEvent(new CustomEvent("timesheet-data-updated"));
  }

  $posthog.capture(AnalyticsEvent.StopwatchToTimesheet, {
    duration: time.value,
    hours,
    minutes,
    source: "stopwatch",
  });
}

function handleStopClick(e?: Event) {
  if (e)
    e.preventDefault();

  showStopConfirmation.value = true;
};

function confirmStop(e?: Event) {
  if (e)
    e.preventDefault();

  stopTimer(e);
  showStopConfirmation.value = false;
};

const stopwatchItems = computed(() => [
  {
    icon: "i-heroicons-play-solid",
    label: "Start",
    variant: "soft",
    condition: !isRunning.value,
    action: startTimer,
  },
  {
    icon: "i-heroicons-pause-solid",
    label: "Pause",
    variant: "soft",
    condition: isRunning.value,
    action: pauseTimer,
  },
  {
    icon: "i-heroicons-stop-solid",
    label: "Stop",
    variant: "soft",
    condition: isRunning.value || isPaused.value,
    action: handleStopClick,
  },
  {
    icon: "i-heroicons-clock-20-solid",
    label: "Convert to Timesheet",
    variant: "solid",
    condition: !isRunning.value && time.value > 0,
    action: handleTimesheetClick,
  },
]);
</script>

<template>
  <div
    v-auto-animate
    class="flex items-center"
  >
    <div
      v-if="isRunning || isPaused"
      class="text-primary mr-2 min-w-[72px] text-center text-xs font-medium tabular-nums"
    >
      {{ formattedTime }}
    </div>

    <div class="flex items-start gap-0">
      <UButtonGroup class="!items-center">
        <UTooltip
          v-for="(item, index) in stopwatchItems.filter(item => item.condition)"
          :key="index"
          :text="item.label"
        >
          <UPopover
            v-if="item.label === 'Stop'"
            v-model:open="showStopConfirmation"
            :popper="{ placement: 'top' }"
          >
            <UButton
              :variant="item.variant as ButtonVariant"
              size="2xs"
              :icon="item.icon"
              color="primary"
              class="border-primary-300 flex !h-[22px] !w-[24px] items-center justify-center border"
              :class="[
                index === stopwatchItems.filter(item => item.condition).length - 1 ? '!rounded-r-md' : '',
              ]"
              :ui="{
                padding: { '2xs': 'px-1.5 py-1.5' },
                icon: { size: { '2xs': 'w-3 h-3' } },
              }"
              @click="item.action"
            />

            <template #panel>
              <div class="space-y-2 p-2">
                <p class="text-xs">
                  Discard current time?
                </p>
                <UButton
                  size="2xs"
                  color="red"
                  class="!rounded-md"
                  block
                  @click="confirmStop"
                >
                  Stop
                </UButton>
                <UButton
                  size="2xs"
                  variant="ghost"
                  color="gray"
                  :padded="false"
                  block
                  @click="showStopConfirmation = false"
                >
                  Cancel
                </UButton>
              </div>
            </template>
          </UPopover>

          <UButton
            v-else
            :variant="item.variant as ButtonVariant"
            size="2xs"
            :icon="item.icon"
            color="primary"
            class="border-primary-300 flex !h-[22px] !w-[24px] items-center justify-center border"
            :class="[
              index === stopwatchItems.filter(item => item.condition).length - 1 ? '!rounded-r-md' : '',
            ]"
            :ui="{
              padding: { '2xs': 'px-1.5 py-1.5' },
              icon: { size: { '2xs': 'w-3 h-3' } },
              base: 'h-full',
            }"
            @click="item.action"
          />
        </UTooltip>
      </UButtonGroup>
    </div>
  </div>
</template>
