<script setup lang="ts">
const { $sentry, $posthog } = useNuxtApp();
const { canGoToPath } = useAuthorizations();

const { user: missiveUser } = storeToRefs(useMissiveStore());
const { info: userInfo, authorizations: userAuthorizations } = storeToRefs(useUserStore());

const initState = ref<"waitingForMissive" | "initPending" | "initFailed" | "initSucceeded">("waitingForMissive");
const initErrorText = ref("");

// Set up app when Missive user is ready
watch(missiveUser, async (newMissiveUser) => {
  if (!newMissiveUser)
    return;

  initState.value = "initPending";
  initErrorText.value = "";

  try {
    const employee = await $fetch("/api/employees/getByEmail", {
      method: "POST",
      body: {
        email: newMissiveUser.email,
      },
    });

    userInfo.value = {
      ...employee,
      avatarUrl: employee.avatarUrl
        ? employee.avatarUrl
        : getAvatarUrl(employee.notionEmployeeId),
    };

    userAuthorizations.value = employee.authorizations;
    const isAuthorized = canGoToPath(useRoute().path);
    if (!isAuthorized)
      throw createError("You are not authorized to access this page.");

    $sentry.setUser({
      id: userInfo.value.notionEmployeeId,
      email: userInfo.value.email,
      username: userInfo.value.name,
    });

    $posthog.identify(userInfo.value.notionEmployeeId, {
      name: userInfo.value.name,
      email: userInfo.value.email,
    });

    initState.value = "initSucceeded";
  }
  catch (error: any) {
    initState.value = "initFailed";
    initErrorText.value = error.statusMessage || error.message || "Unknown error. Please contact IT.";
  }
}, { immediate: true });

// Check authorizations in route changes
const router = useRouter();
router.beforeEach((to, _, next) => {
  if (!userInfo.value)
    return next();

  const isAuthorized = canGoToPath(to.path);
  if (!isAuthorized) {
    initState.value = "initFailed";
    initErrorText.value = "You are not authorized to access this page.";
    return next(false);
  }

  next();
});

function handleErrors(error: any) {
  console.error(error);
  $sentry.captureError(error);
}
</script>

<template>
  <main class="min-h-screen bg-gray-50 py-4 dark:bg-gray-950">
    <HelpTicket class="fixed bottom-4 right-4 z-10" />
    <NuxtErrorBoundary @error="handleErrors">
      <UContainer>
        <div
          v-if="initState === 'waitingForMissive' || initState === 'initPending'"
          class="mt-8 flex flex-col items-center justify-center gap-3"
        >
          <div v-if="initState === 'waitingForMissive'">
            <GText class="text-center">
              Waiting for Missive...
            </GText>
            <GText
              class="text-center"
              variant="secondary"
              size="sm"
            >
              You might want to refresh the page if this takes more than a few seconds.
            </GText>
          </div>
          <GSpinner size="lg" />
        </div>

        <div
          v-else-if="initState === 'initSucceeded'"
          class="mb-4 flex flex-col gap-6"
        >
          <TheNavigationMissive />
          <slot />
        </div>

        <div v-else>
          <GError
            title="Oops! Something went wrong."
            :description="initErrorText"
          />
          <div
            v-if="!initErrorText.includes('Please contact HR')"
            class="mt-8 flex justify-center"
          >
            <img
              src="/missive-refresh.png"
              class="w-64"
            >
          </div>
        </div>
      </UContainer>

      <!-- Error Display -->
      <template #error="{ error }">
        <GError
          title="Oops! Something went wrong."
          description="Can you please refresh the page and try again?"
          :action="() => error.value = null"
        />
        
      </template>
    </NuxtErrorBoundary>
  </main>
</template>
