<script setup lang="ts">
import type { GNavItem } from "~/types/navigation";

const avatarUrl = computed(() => useUserStore().info?.avatarUrl);
const firstName = computed(() => useUserStore().info?.name.split(" ")[0]);
const now = useNow();

const { canGoToPath } = useAuthorizations();
const { missiveNavItems } = useNavigation();
const toast = useToast();

const navItems = computed(() => {
  const permitted = missiveNavItems.filter(item => canGoToPath(item.to));
  const formatted = permitted.map((item) => {
    const current = useRoute().path;

    return {
      ...item,
      active: current.includes(item.to),
    };
  });

  return formatted;
});

async function handleNavigate(item: GNavItem) {
  if (item.disabled) {
    toast.add({
      title: "This module is temporarily unavailable",
      description: "Check back in a few hours.",
      color: "amber",
      icon: "i-heroicons-exclamation-triangle",
    });
  }
  else {
    navigateTo(item.to);
  }
}
</script>

<template>
  <div class="flex flex-col">
    <!-- Top Section: Welcome + Nav Buttons -->
    <div class="flex justify-between">
      <!-- Welcome Message -->
      <div class="flex items-start gap-2">
        <div
          class="flex items-center justify-center rounded-full"
          :class="[avatarUrl?.includes('dicebear') ? 'bg-primary-200' : 'border-primary-300 border-2 p-0.5']"
        >
          <UAvatar
            :src="avatarUrl"
            alt="Avatar"
            size="sm"
          />
        </div>
        <div class="flex flex-col">
          <GText
            size="none"
            class="text-sm font-bold xs:text-base"
          >
            Hi {{ firstName }}!
          </GText>
          <GText
            size="none"
            variant="secondary"
            class="text-xs xs:text-sm"
          >
            {{ now.toLocaleString('en-US', { timeZone: 'America/New_York', hour: 'numeric', minute: 'numeric' }) }} (NY)
          </GText>
        </div>
      </div>

      <!-- Navigation Buttons -->
      <div class="flex items-start gap-0">
        <div
          v-for="(item, idx) in navItems"
          :key="idx"
        >
          <UTooltip :text="item.label">
            <UButton
              size="xs"
              :variant="item.active ? 'solid' : 'soft'"
              :icon="item.icon"
              class="border-primary-300"
              :class="[idx === 0 ? 'border-y border-l' : idx === navItems.length - 1 ? 'border-y border-r' : 'border-y']"
              :ui="{
                rounded: idx === 0 ? 'rounded-r-none' : idx === navItems.length - 1 ? 'rounded-l-none' : 'rounded-none',
              }"
              @click.prevent="handleNavigate(item)"
            />
          </UTooltip>
        </div>
      </div>
    </div>

    <!-- Radio Grape Section -->
    <div class="relative">
      <div class="mt-1">
        <UAccordion
          open-icon=""
          :items="[{
            label: 'Radio Grape',
            icon: 'i-heroicons-radio-20-solid',
            size: '2xs',
            variant: 'link',
            padded: false,
          }]"
        >
          <template #default="{ item }">
            <UButton
              v-bind="item"
            >
              <template #leading>
                <UIcon :name="item.icon" />
              </template>
            </UButton>
          </template>

          <template #item="">
            <iframe
              :src="useRuntimeConfig().public.grapeRadioUrl"
              class="mt-2 flex h-[28rem] w-full overflow-hidden rounded"
              frameborder="0"
              referrerpolicy="no-referrer"
              loading="lazy"
            />
          </template>
        </UAccordion>
      </div>
      <!-- Stopwatch Section -->
      <div class="absolute right-0 top-0">
        <Stopwatch />
      </div>
    </div>
  </div>
</template>
